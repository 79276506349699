<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
    VclList,
    Stat,
  },
  data() {
    return {
      id: this.$route.params.id,

      client: null,

      statData: null,

      table: {
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  methods: {
    getClient() {
      this.table.body = null

      this.table.loading = true
      this.table.errored = false
      this.table.empty = false

      api
        .get('network/clients/' + this.id)
        .then(response => {
          if (response.data.status=='success') {
            this.client = response.data.client;
            this.table.body = response.data.contracts;

            this.statData = [
              {
                title: "Saldo Disponível",
                value: this.$options.filters.currency(
                  response.data.client.balance
                ),
              },
              {
                title: "Total Aportado",
                value: this.$options.filters.currency(
                  response.data.client.contracts
                ),
              },
              {
                title: "Rendimentos Totais",
                value: this.$options.filters.currency(
                  response.data.client.profits
                ),
              },
            ];
          } else {
            this.table.body = null
          }
        })
        .catch(error => {
          if (error) {
            this.$router.push('/network/clients');
          }
        })
        .finally(() => {
          this.table.loading = false
          if (this.table.body=='' || this.table.body==null) {
            this.table.empty = true
          }
        })
    },
  },
  mounted() {
    this.getClient()
  }
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/network/clients">Meus Clientes</router-link></li>
      <li class="breadcrumb-item d-none d-sm-block">Cliente</li>
    </ol>

    <div v-if="client" class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3 class="text-uppercase">{{ client.name }}</h3>
        <p></p>
      </div>
    </div>

    <div v-if="client" class="d-flex mb-4" style="gap: 20px;">
      <div class="bg-soft-success rounded p-3 font-size-17">
        {{ client.email }}
      </div>
      <div class="bg-soft-info rounded p-3 font-size-17">
        {{ client.cellphone }}
      </div>
    </div>

    <div v-if="client" class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-3">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <h5 v-if="client" class="my-4">Contratos</h5>

    <div class="card">
      <div class="card-body">
        <div v-if="table.errored">
          {{
          t(
          "We apologize, we are unable to retrieve information at this time. Please try again later."
          )
          }}
        </div>
        <div v-else-if="table.empty">{{ t("No records found.") }}</div>
        <div v-else class="table-responsive">
          <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
          <table v-else class="table table-nowrap">
            <thead class="thead-light">
              <tr>
                <th>#</th>
                <th>Período</th>
                <th>Valor</th>
                <th>Rendimentos</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(td, index) in table.body" :key="index">
                <td class="align-middle">
                  #{{ td.id.split('-')[0] }}<br>
                  <span class="font-size-11 text-uppercase">{{ td.date_added }}</span>
                </td>
                <td class="align-middle">
                  {{ td.period }} dias<br>
                  <b-progress :value="td.progress" :max="td.period" class="mb-0" height="20px"></b-progress>
                  <span class="text-primary font-size-11 text-uppercase" v-if="td.status == 'approved'">+ Carência em andamento</span>
                </td>
                <td class="align-middle">{{ td.value | currency }}</td>
                <td class="align-middle">{{ td.profits | currency }}</td>
                <td class="align-middle">
                  <span v-if="td.status == 'approved'" class="p-3 px-4 badge badge-soft-success font-size-14">{{ t("Em andamento") }}</span>
                  <span v-else-if="td.status == 'finalized'" class="p-3 px-4 badge badge-soft-info font-size-14">{{ t("Finalizado") }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>